* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .container {
    max-width: 90%;
  }

}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.5;
}



.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  font-size: 25px;
}

.header h1 {
  font-size: 24px;
}

.main {
  padding: 40px 0;
}

.hero {
  /* background-image: url('path/to/hero-image.jpg'); */
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
}

.hero-content {
  text-align: center;
  color: #fff;
}

.hero-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 40px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #555;
}

.features {
  margin-bottom: 40px;
}

/* Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
}

.headerHome {
  position: relative;
  width: 100%;
  height: 100vh;
  
}

/* Logo */
.logo img {
  width: 150px;
  height: auto;
  margin-left: 100px;
}

/* Navigation Links */
.nav-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin-left: 30px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.nav-links a:hover {
  color: #FFD700;
}

.nav-links li:last-child {
  margin-right: 100px;
}

.banner-image {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 1;
  z-index: -1;
}

.text-box {
  background-color: #f1f1f1;
  padding: 50px;
  margin-left: 100px;
  display: inline-block;
  min-width: 200px;
  min-height: 200px;
  max-width: 900px;
  opacity: 0.7;
  font-family: Georgia, 'Times New Roman', Times, serif;
  border-radius: 15px;
  margin-top: 50px;
  justify-content: center ;
  align-items: center;
  text-align: left;

}



.text-box h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text-box p {
  font-size: 16px;
  margin-bottom: 0;
}

.large-font {
  font-size: 24px; 
}

.small-font {
  font-size: 12px; 
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/*About Us*/
.about-us {
  padding: 3%;

  border-radius: 8px;
  margin-left: -3%;
  margin-right: -3%;

}

.about-us h1 {
  color: #333;
  font-size: 38px;
  margin-bottom: 16px;
}

.about-us-content {
  color: #555;
  text-align: left;
  font-size: 20px;

  
}
 s
.about-us-content p {
  margin-bottom: 16px;
}

.container {
  display: inline-block;
  justify-content: center; /* Horizontally center the content */
  height: 100vh;
  text-align: center;
  /* background-color: #f1f1f1; */
}

.title {
  text-align: left;
  margin-bottom: 2%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0 20%; 
}

p {
  margin-top: 0; /* Remove the default top margin of the paragraphs */
}

.first-paragraph {

  /* position: relative; */
  /* width:100%; */

  margin: 0 20%; 
}

.background-color{
  background-color: #f1f1f1;
  padding-top: 3%;
  padding-bottom: 3%;
}

.service-padding{
  padding-top: 3%;
  padding-bottom: 3%;
}

.service-content{

  text-align: left;
  font-size: 20px;
  margin: 0 20%; 
  text-justify: auto;
}

.contact-content{

  text-align: center;
  font-size: 20px;
  margin: 0 20%; 
  text-justify: auto;
}


.contact-title {
  text-align: center;
  margin-bottom: 1%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0 20%; 
  font-size: 60px;
}








